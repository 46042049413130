

$(document).on("load", () => {
  init()

  let iconBtn = document.querySelectorAll("button.icon")
  let inCart  = document.querySelectorAll(".in-cart")
  if (iconBtn) {
    console.log(iconBtn)
    iconBtn.forEach(element => {
      element.addEventListener("click",(e)=>{
        console.log("hello")
        clickIncart(element)
      })
    });
  }
  showIncart(inCart)
})
$(document).on("turbolinks:load", () => {
  init()
  let iconBtn = document.querySelectorAll("button.icon")
  let inCart  = document.querySelectorAll(".in-cart")
  if (iconBtn) {
    console.log(iconBtn)
    iconBtn.forEach(element => {
      element.addEventListener("click",(e)=>{
        console.log("hello")
        clickIncart(element)
      })
    });
  }
  showIncart(inCart)
})

const init = ()=>{
  let datFrom = document.querySelector("#search_date_from")
  let datTo = document.querySelector("#search_date_to")
  let searchInput = document.querySelector(".search-input")
  let datText = document.querySelectorAll(".date-value")
  let clearBtn = document.querySelector(".clear-btn")


  const dateUpdate = (element,p)=>{
  element.addEventListener("change",(e)=>{
    datText.forEach(e => {
      if(e.classList.contains(p)){
        if(element.value == ""){
          e.innerHTML = "---- / -- / --"
        }else{
          e.innerHTML = element.value
        }
      }
    });
    
  })
  }

  const clearFx = (input)=>{
    
    datText.forEach(element => {
      element.innerHTML = "---- /-- / --"
    });
    input.value = ""
    const e = new Event("change")
    input.dispatchEvent(e)
  }
  clearBtn.addEventListener("click",()=>{
    clearFx(datFrom)
    clearFx(datTo)
  })

  dateUpdate(datFrom,"date-from")
  dateUpdate(datTo,"date-to")









  let thumbnail = document.querySelectorAll(".video-list-thumbnail-con")

  thumbnail.forEach(element => {
    element.addEventListener("mouseenter" ,()=>{
      // expand Target
      // element.style.width = "350px"
  })
  });



  // Nav Drop Down Function
  const dropDownFx = (items)=> {
        let navDropdowns = document.querySelectorAll(".navigation ul li")
        navDropdowns.forEach(item => {
          let droppable = item.getAttribute("data-expandable")
          if (droppable) {
            let content = item.children[1]
            let clickable = item.children[0]
            let filterCheck = content.children[0].classList.contains("filters")
            content.classList.add("undrop")
           clickable.addEventListener("click", (e)=>{
              e.preventDefault();
             if (content.classList.contains("dropped")) {
              content.classList.add("undrop")
              content.classList.remove("dropped")
              item.classList.remove("light-bg")
             } else {
              content.classList.remove("undrop")
              content.classList.add("dropped")
              if (filterCheck) {
                item.classList.add("light-bg")
              }

             }
           })
          }
          // item.addEventListener("click",()=>{})
        })
  }

  let filterItems = document.querySelectorAll(".filter-list")
  dropDownFx(filterItems)

const collapseNav = ()=>{
    let collapseBtn = document.querySelector(".nav-collapse-btn")
    let nav = document.querySelector(".navigation")
    let navItem = document.querySelectorAll(".nav-text")
    let body = document.querySelector("body")
    let content = document.querySelector("body .content")
    let copyright = document.querySelector(".nav-collapse-btn p")
    let navList = document.querySelectorAll(".navigation ul li a")
    let navLi = document.querySelectorAll(".navigation ul li ")

    navLi.forEach((li)=>{
      li.addEventListener("click", (e)=>{
        if(nav.classList.contains("navigation-hidden")){
            collapseBtn.style.position = "fixed"
            collapseBtn.style.maxWidth = "250px"
            collapseBtn.style.height = "unset"
            nav.classList.remove("navigation-hidden")
            navItem.forEach(e => {
              e.style.display = "inline"
            })
            body.classList.remove("wide")
            copyright.style.display = "inline"
            content.style.display = "calc(100vw - 50px)"
        }else{
          if (li.classList.contains("nav-collapse-btn")) {
          nav.classList.add("navigation-hidden")
          body.classList.add("wide")
          navItem.forEach(e => {
            e.style.display = "none"
          })
          copyright.style.display = "none"
          content.style.display = "calc(100vw - 259px)"
          collapseBtn.style.position = "absolute"
          collapseBtn.style.maxWidth = "49px"
          collapseBtn.style.height = "50px"

          let navDrop = document.querySelectorAll(".expandable-content")
          navDrop.forEach(nav => {
            nav.classList.remove( "dropped")
            nav.classList.add( "undrop")
            nav.parentElement.classList.remove("light-bg")
          })
        }
        }
        navList.forEach(e => {
          e.addEventListener("click", ()=>{
            if(!nav.classList.contains("navigation-hidden") ){
                nav.classList.remove("navigation-hidden")
                navItem.forEach(e => {
                e.style.display = "inline"
              })
              body.classList.remove("wide")
              copyright.style.display = "inline"
              content.style.display = "calc(100vw - 50px)"
            }
          })
        });
      })
    })
}
collapseNav()




  let genDropDown = (item, icon, content)=>{

    let hello = false
    item.addEventListener("click",()=>{
    if (item.classList.contains("is_closed")) {
        icon.style.transform = "rotate(0deg)"
        item.classList.remove("is_closed")
    }else{
        icon.style.transform = "rotate(180deg)"
        item.classList.add("is_closed")
    }
  })

  }

  let proDd = document.querySelectorAll(".drop-down-header")
  proDd.forEach(elem => {
    let proIcon = elem.children[1]
    let content = elem.parentElement.children[1]
    genDropDown(elem,proIcon, content)
  });

  let filterBtns = document.querySelectorAll(".filter-section")
  filterBtns.forEach(elem => {
    let proIcon = elem.children[0]
    let content = elem.parentElement.children[1]
    genDropDown(elem,proIcon, content)
  });


  let filterSubBtns = document.querySelectorAll(".filter-list  .sub-list")
  filterSubBtns.forEach(elem => {
    if (elem.children[0]) {
      elem.children[0].style.display = "inline-block"
    }
    let proIcon = elem.children[0]
    let content = elem.parentElement.children[1]
    genDropDown(elem,proIcon, content)
  });


const filterCheck = document.querySelectorAll(".checkbox label .check_boxes")
const previewHero = document.querySelector(".preview")
const pageContent = document.querySelector(".content")
const pageRec = document.querySelector("#recommendations")
let checkArr = []
filterCheck.forEach(check => {
  check.addEventListener('change',()=>{
    filterCheck.forEach(e => {
      checkArr.push(e.checked)
    });

    console.log(checkArr)
    if (checkArr.includes(true)) {
      previewHero.style.display = "none"
      pageRec.style.display = "none"
      pageContent.classList.add("page")
      checkArr= []
     }else{
      previewHero.style.display = "block"
      pageRec.style.display = "block"
      pageContent.classList.remove("page")
      checkArr= []
     }
    })
});

const prevDateHide = [datFrom,datTo,searchInput]
prevDateHide.forEach(check => {
  check.addEventListener('change',()=>{
    if (!check.value == "" ) {
        previewHero.style.display = "none"
        pageRec.style.display = "none"
        pageContent.classList.add("page")
     }else{
       filterCheck.forEach(input => {
         checkArr.push(input.checked)
       });
       if (checkArr.includes(true)) return

        previewHero.style.display = "block"
        pageRec.style.display = "block"
        pageContent.classList.remove("page")
     }
    })
});





}


// Mobile Responsiveness
window.addEventListener("load",()=>{

  showIncart()
  let navbar  = document.querySelector(".navigation")
  let navItem = document.querySelectorAll(".nav-text")
  let body = document.querySelector("body")
  let content= document.querySelector(".content")
  let calBtn= document.querySelector(".nav-collapse-btn")
  
  let windowSize = window.innerWidth
  if(windowSize <= "480"){
    navbar.classList.add("navigation-hidden")
    // navbar.style.height = window.innerHeight
    navItem.forEach(element => {
      element.style.display = "none"
    });
    body.classList.add("wide")
    content.style.width = "calc("+windowSize+"px - 70px)"
    calBtn.style.bottom = window.innerHeight
  }
  

  })



const showIncart = (inCart )=>{
    if (inCart) {
      inCart.forEach(element => {
        element.innerHTML = "<i style='color:#B47DE0' class='fas fa-check-circle'></i>"
        console.log(element)
      });
    }
}
const clickIncart = (element )=>{
        element.innerHTML = "<i style='color:#B47DE0' class='fas fa-check-circle'></i>"
}