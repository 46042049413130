const filterFX = ()=>{
    // console.log("filterFX")
    let filterParents = document.querySelectorAll(".filter-parent")
    for (let i = 0; i < filterParents.length; i++) {
        const filterParent = filterParents[i];
        // select first child
        let dropItem = filterParent.children[0].children[2].children[0]
        // select subitems
        let subItemInput = filterParent.children[1].children
        // console.log(subItemInput)
        dropItem.addEventListener("click",()=>{
        if(dropItem.checked == true){
            if (subItemInput) {
                for (let i = 0; i < subItemInput.length; i++) {
                    const item = subItemInput[i]
                    let subsub = item.children[0].children[2].children[0].children[0]
                    subsub.click()
                    subsub.checked = false
                }
            }
        }else{
            if (subItemInput) {
                for (let i = 0; i < subItemInput.length; i++) {
                    const item = subItemInput[i]
                    let subsub = item.children[0].children[2].children[0].children[0]
                    subsub.click()
                    subsub.checked = "checked"
                }
            }
        }
        })
    }
}
window.addEventListener("load", ()=>{
   // console.log("hello mi a work")
    filterFX()

})

$(document).on("turbolinks:load", () => {
    filterFX()
})
